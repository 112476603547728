/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Typography from 'components/Typography';

const dots = ['#00FFF6', '#00E6FF', '#00BBFF', '#41B0FF', '#418AFF', '#415AFF'];

const ShowcaseCard = ({
  image,
  logo,
  backgroundLinear,
  logoAlign = 'left',
  logoHeight = 60,
  features = [],
  height = 580,
  className,
}: {
  image: string;
  backgroundLinear: string;
  logoAlign?: 'left' | 'center';
  logo: string;
  logoHeight?: number;
  features?: {
    label: string;
    highlight?: string;
    link?: string;
    dotPosition?: number;
  }[];
  height?: number;
  className?: string;
}) => (
  <div
    css={css`
      position: relative;
      border-radius: 24px;
      overflow: hidden;
      background:
        url(${image}) no-repeat top right,
        ${backgroundLinear};
      background-size: 100%;
      background-color: red;
      height: ${height}px;
    `}
    className={className}
  >
    <div
      css={css`
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 36px 36px;
        background-image: linear-gradient(
          135deg,
          rgba(29, 29, 29, 0.8),
          rgba(0, 0, 0, 0.8)
        );
        backdrop-filter: blur(20px);
      `}
    >
      <div
        css={css`
          text-align: ${logoAlign === 'center' ? 'center' : 'left'};
          height: 60px;
        `}
      >
        <img src={logo} height={`${logoHeight}px`} alt="Logo" />
      </div>
      {features?.length > 0 && (
        <ul
          css={css`
            padding: 0;
            list-style: none;
          `}
        >
          {features?.map(({ label, highlight, link, dotPosition }, index) => (
            <li
              key={index}
              css={css`
                position: relative;
                padding-left: 34px;
                text-align: left;

                vertical-align: bottom;

                &:before {
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  border-radius: 10px;
                  top: ${dotPosition ? `${dotPosition}px` : '50%'};
                  left: 0;
                  ${dotPosition ? '' : 'transform: translateY(-50%);'}
                  background-color: ${dots[index]};
                  content: '';
                }
              `}
            >
              <Typography
                size={20}
                lineHeight={36}
                mediumSize={18}
                mediumLineHeight={24}
                smallSize={16}
                smallLineHeight={24}
              >
                {label}
              </Typography>
              {highlight && (
                <Typography
                  size={24}
                  lineHeight={36}
                  mediumSize={24}
                  mediumLineHeight={24}
                  smallSize={18}
                  smallLineHeight={24}
                  weight="600"
                  gradient
                  css={css`
                    padding-left: 4px;
                  `}
                >
                  {highlight}
                </Typography>
              )}
              {link && (
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  css={css`
                    text-decoration: none;
                    word-wrap: break-word;
                    display: inline-block;

                    &:hover {
                      text-decoration: underline;
                    }
                  `}
                >
                  <Typography
                    color="#00FFF6"
                    css={css`
                      padding-left: 4px;
                    `}
                  >
                    {link}
                  </Typography>
                </a>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  </div>
);

export default ShowcaseCard;
