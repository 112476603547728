/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Wrapper from 'components/Wrapper';
import { ReactComponent as Hand } from 'assets/svgs/hand.svg';
import { ReactComponent as Lock } from 'assets/svgs/lock.svg';
import { ReactComponent as Target } from 'assets/svgs/target.svg';
import { useResponsive } from 'ahooks';
import Typography from 'components/Typography';

const OurValues = () => {
  const responsive = useResponsive();

  console.log(responsive);

  return (
    <section
      id="our-values"
      css={css`
        padding: 100px 0;

        @media screen and (max-width: 1000px) {
          padding: 50px 0;
        }
      `}
    >
      <Wrapper>
        <Typography
          as="h2"
          size={20}
          lineHeight={30}
          gradient
          css={css`
            position: relative;
            text-align: center;
            padding: 28px 0;
            margin: 0 0 56px;

            &:after {
              position: absolute;
              width: 200px;
              height: 1px;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              content: '';
              background: linear-gradient(to right, #00fff6, #415aff);
            }
          `}
        >
          Our values
        </Typography>
        <ul
          css={css`
            display: flex;
            justify-content: space-evenly;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              flex: 1 1 33.3%;
            }

            svg {
              max-width: 120px;

              @media screen and (max-width: 768px) {
                max-width: 90px;
              }
            }
          `}
        >
          <li>
            <Hand />
            <Typography
              as="p"
              size={32}
              lineHeight={45}
              mediumSize={24}
              mediumLineHeight={32}
              smallSize={16}
              smallLineHeight={24}
              weight="600"
              gradient
            >
              Winning Relationships
            </Typography>
            <Typography>
              We focus on building long-term partnerships that ensure mutual
              success.
            </Typography>
          </li>
          <li>
            <Lock />
            <Typography
              as="p"
              size={32}
              lineHeight={45}
              mediumSize={24}
              mediumLineHeight={32}
              smallSize={16}
              smallLineHeight={24}
              weight="600"
              gradient
            >
              Transparency
            </Typography>
            <Typography>
              We provide clear insights into all processes, fostering trust
              through openness.
            </Typography>
          </li>
          <li>
            <Target />
            <Typography
              as="p"
              size={32}
              lineHeight={45}
              mediumSize={24}
              mediumLineHeight={32}
              smallSize={16}
              smallLineHeight={24}
              weight="600"
              gradient
            >
              Results Driven
            </Typography>
            <Typography>
              We make data-driven decisions that maximize impact and deliver
              real results.
            </Typography>
          </li>
        </ul>
      </Wrapper>
    </section>
  );
};

export default OurValues;
