/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Typography from 'components/Typography';
import Wrapper from 'components/Wrapper';
import HeroLeft from 'assets/images/hero-left.png';
import HeroRight from 'assets/images/hero-right.png';
import Faster from 'assets/images/faster.png';
import Button from 'components/Button';
import { useResponsive } from 'ahooks';

const HeroBanner = () => {
  const responsive = useResponsive();

  return (
    <section
      css={css`
        padding-bottom: 100px;
        background-image: url(${HeroLeft}), url(${HeroRight});
        background-position:
          -100px,
          right top;
        background-repeat: no-repeat;
        background-size: 30%, 20%;

        @media screen and (max-width: 1000px) {
          padding-bottom: 70px;
        }

        @media screen and (max-width: 768px) {
          padding-bottom: 40px;
        }
      `}
    >
      <Wrapper>
        <h1
          css={css`
            text-align: center;
            margin-bottom: 80px;

            @media screen and (max-width: 1000px) {
              margin-bottom: 60px;
            }

            @media screen and (max-width: 768px) {
              margin-bottom: 40px;
            }
          `}
        >
          <Typography
            css={css`
              display: inline-block;
              padding: 12px 34px;
              margin-bottom: 48px;
              position: relative;

              &:before {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                border-radius: 40px;
                background: linear-gradient(to right, #00fff6, #415aff);
                content: '';
                z-index: 0;
              }

              &:after {
                position: absolute;
                top: 1px;
                right: 1px;
                left: 1px;
                bottom: 1px;
                border-radius: 40px;
                background: black;
                content: '';
                z-index: 0;
              }
            `}
          >
            <span
              css={css`
                position: relative;
                z-index: 10;
              `}
            >
              Driving Digital Asset Success in the Web3 Era
            </span>
          </Typography>
          <Typography
            css={css`
              display: block;
            `}
          >
            We deliver strategic
          </Typography>
          <Typography
            as="p"
            size={52}
            lineHeight={78}
            mediumSize={40}
            mediumLineHeight={48}
            smallSize={32}
            smallLineHeight={40}
            weight="700"
            gradient
            css={css`
              display: block;
              margin: 0 0 4px 0;
            `}
          >
            Digital asset advisory
            {responsive.middle && (
              <img
                src={Faster}
                alt="Boost"
                css={css`
                  height: 78px;
                  display: inline;
                  padding-left: 18px;
                  vertical-align: bottom;

                  @media screen and (max-width: 1000px) {
                    height: 40px;
                  }

                  @media screen and (max-width: 768px) {
                    height: 32px;
                    padding-left: 8px;
                  }
                `}
              />
            )}
          </Typography>
          <Typography
            css={css`
              display: block;
              margin-bottom: 4px;
            `}
          >
            Coupled with
          </Typography>
          <Typography
            as="p"
            size={52}
            lineHeight={78}
            mediumSize={40}
            mediumLineHeight={48}
            smallSize={32}
            smallLineHeight={40}
            weight="700"
            gradient
            css={css`
              display: block;
              margin: 0 0 4px 0;
            `}
          >
            Crypto asset retail and marketing support
          </Typography>
          <Typography>
            To drive the success of global projects and companies in the Web3
            era.
          </Typography>
        </h1>
        <Button
          onClick={() => {
            const element = document.getElementById('contact-us');
            element?.scrollIntoView({
              behavior: 'smooth',
            });
          }}
        >
          Contact us now
        </Button>
      </Wrapper>
    </section>
  );
};

export default HeroBanner;
