/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Wrapper from 'components/Wrapper';
import { ReactComponent as Logo } from 'assets/svgs/logo.svg';

const Header = () => {
  return (
    <header
      css={css`
        padding: 70px;

        @media screen and (max-width: 768px) {
          padding: 35px 0;
        }
      `}
    >
      <Wrapper
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            height: 60px;
            @media screen and (max-width: 768px) {
              height: 30px;
            }
          `}
        >
          <Logo />
        </div>
        <nav>
          <ul
            css={css`
              margin: 0;
              padding: 0;
              list-style: none;
              display: flex;

              li {
                padding: 12px 22px;
                margin: 0 4px;
                font-size: 20px;
                line-height: 28px;
                font-weight: 600;
                cursor: pointer;
                border-radius: 40px;

                @media screen and (max-width: 768px) {
                  padding: 8px 22px;
                  font-size: 14px;
                  line-height: 20px;
                }

                &:hover {
                  background-color: #222;
                }
              }
            `}
          >
            <li
              css={css`
                @media screen and (max-width: 768px) {
                  display: none;
                }
              `}
              onClick={() => {
                const element = document.getElementById('services');
                element?.scrollIntoView({
                  behavior: 'smooth',
                });
              }}
            >
              Service
            </li>
            <li
              css={css`
                @media screen and (max-width: 768px) {
                  display: none;
                }
              `}
              onClick={() => {
                const element = document.getElementById('showcase');
                element?.scrollIntoView({
                  behavior: 'smooth',
                });
              }}
            >
              Showcase
            </li>
            <li
              css={css`
                position: relative;

                &:before {
                  position: absolute;
                  top: 0;
                  right: 0;
                  left: 0;
                  bottom: 0;
                  border-radius: 40px;
                  background: linear-gradient(to right, #00fff6, #415aff);
                  content: '';
                  z-index: 0;
                }

                &:after {
                  position: absolute;
                  top: 1px;
                  right: 1px;
                  left: 1px;
                  bottom: 1px;
                  border-radius: 40px;
                  background: black;
                  content: '';
                  z-index: 0;
                }

                &:hover:after {
                  background: #222;
                }
              `}
              onClick={() => {
                const element = document.getElementById('contact-us');
                element?.scrollIntoView({
                  behavior: 'smooth',
                });
              }}
            >
              <span
                css={css`
                  position: relative;
                  z-index: 10;
                `}
              >
                Contact us
              </span>
            </li>
          </ul>
        </nav>
      </Wrapper>
    </header>
  );
};

export default Header;
