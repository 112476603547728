/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import ShowcaseCard from 'components/ShowcaseCard';
import Wrapper from 'components/Wrapper';
import SonoRusLogo from 'assets/images/sonorus.png';
import SonoRusBackground from 'assets/images/sonorus-bg.png';
import AgentLayerLogo from 'assets/images/agent-layer.png';
import AgentLayerBackground from 'assets/images/agent-layer-bg.png';
import OxvmLogo from 'assets/images/oxvm.png';
import OxvmBackground from 'assets/images/oxvm-bg.png';
import SonicLogo from 'assets/images/sonic.png';
import SonicBackground from 'assets/images/sonic-bg.png';
import { useResponsive } from 'ahooks';

const ShowCase = () => {
  const responsive = useResponsive();

  console.log(responsive);

  return (
    <section
      id="showcase"
      css={css`
        padding: 100px 0;

        @media screen and (max-width: 1000px) {
          padding: 50px 0;
        }
      `}
    >
      <Wrapper>
        <ul
          css={css`
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            gap: 60px;
            flex-wrap: wrap;

            @media screen and (max-width: 768px) {
              display: block;
            }

            > li {
              flex: 1 1 calc(50% - 30px);
              width: 100%;

              @media screen and (max-width: 768px) {
                margin-bottom: 32px;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          `}
        >
          <li>
            <ShowcaseCard
              image={SonoRusBackground}
              logo={SonoRusLogo}
              height={responsive.large ? 580 : responsive.middle ? 500 : 420}
              logoHeight={40}
              backgroundLinear="linear-gradient(45deg, #222222 14%, #E26736 37%, #E74A93 56%, #422D36 80%, #282828 100%)"
              features={[
                {
                  label: 'Total supply:',
                  highlight: '50,000',
                },
                {
                  label: 'Initial price:',
                  highlight: '$600',
                },
                {
                  label: 'Current revenue:',
                  highlight: '~$10m',
                },
                {
                  label: 'Period: 6 months - from October 2023 (bear market)',
                  dotPosition: 12,
                },
                {
                  label: 'Global Community Coverage',
                },
                {
                  label: 'Visit project:',
                  link: 'https://sonorus.network/djnode',
                  dotPosition: 12,
                },
              ]}
            />
          </li>
          <li>
            <ShowcaseCard
              image={AgentLayerBackground}
              logo={AgentLayerLogo}
              height={responsive.large ? 580 : responsive.middle ? 500 : 420}
              logoHeight={40}
              backgroundLinear="linear-gradient(45deg, #222222 14%, #B1E457 46%, #68C83C 52%, #32402B 67%, #282828 100%)"
              features={[
                {
                  label: 'Total supply:',
                  highlight: '20,000',
                },
                {
                  label: 'Initial price:',
                  highlight: '$800',
                },
                {
                  label: 'Current revenue:',
                  highlight: '~$7m',
                },
                {
                  label:
                    'Period: In 3 days after node launch, from June 3 - June 5, $3M fund raising is reached.',
                  dotPosition: 12,
                },

                {
                  label: 'Visit project:',
                  link: 'https://apgn.agentlayer.xyz/home',
                  dotPosition: 12,
                },
              ]}
            />
          </li>
          <li>
            <ShowcaseCard
              image={OxvmBackground}
              logo={OxvmLogo}
              logoHeight={60}
              height={responsive.large ? 360 : 280}
              logoAlign="center"
              backgroundLinear="linear-gradient(45deg, #E26736 53%, #080808 79%, #282828 100%)"
            />
          </li>
          <li>
            <ShowcaseCard
              image={SonicBackground}
              logo={SonicLogo}
              logoHeight={47}
              height={responsive.large ? 360 : 280}
              logoAlign="center"
              backgroundLinear="linear-gradient(45deg, #0000B9 53%, #080808 79%, #282828 100%)"
            />
          </li>
        </ul>
      </Wrapper>
    </section>
  );
};

export default ShowCase;
