/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Typography from 'components/Typography';
import { PropsWithChildren } from 'react';

const InfoCard = ({
  image,
  title,
  children,
  className,
}: PropsWithChildren<{ image: string; title: string; className?: string }>) => (
  <div
    css={css`
      width: 100%;
    `}
    className={className}
  >
    <div>
      <div
        css={css`
          border-radius: 24px;
          overflow: hidden;
          height: 240px;
          background: url(${image}) no-repeat center center;
          background-size: cover;

          @media screen and (max-width: 1000px) {
            height: 120px;
          }

          @media screen and (max-width: 768px) {
            height: 200px;
          }
        `}
      />

      <Typography
        as="h3"
        size={28}
        lineHeight={36}
        mediumSize={24}
        mediumLineHeight={32}
        smallSize={24}
        smallLineHeight={32}
        weight="600"
        css={css`
          margin: 32px 0 24px;
        `}
      >
        {title}
      </Typography>
      <Typography
        size={18}
        lineHeight={22}
        mediumSize={16}
        mediumLineHeight={20}
        color="#BDBDBD"
        as="div"
        css={css`
          text-align: left;

          > p {
            margin-bottom: 18px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        `}
      >
        {children}
      </Typography>
    </div>
  </div>
);

export default InfoCard;
