/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Typography from 'components/Typography';
import { PropsWithChildren, ButtonHTMLAttributes } from 'react';

const Button = ({
  type = 'button',
  children,
  onClick,
}: PropsWithChildren<{
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: () => void;
}>) => {
  return (
    <button
      type={type}
      css={css`
        background: linear-gradient(to right, #00fff6, #415aff);
        padding: 11px 40px;
        border-radius: 40px;
        border: 0;
        cursor: pointer;
      `}
      onClick={onClick}
    >
      <Typography color="black" weight="700">
        {children}
      </Typography>
    </button>
  );
};

export default Button;
