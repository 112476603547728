/** @jsxImportSource @emotion/react */

import Header from './components/Header';
import './App.css';
import { css } from '@emotion/react';
import HeroBanner from 'pages/Home/HeroBanner';
import Service from 'pages/Home/Service';
import ShowCase from 'pages/Home/ShowCase';
import ContactUs from 'pages/Home/ContactUs';
import { configResponsive } from 'ahooks';
import OurValues from 'pages/Home/OurValues';

configResponsive({
  small: 0,
  middle: 768,
  large: 1000,
});

function App() {
  return (
    <div
      className="App"
      css={css`
        color: white;
      `}
    >
      <Header />
      <HeroBanner />
      <Service />
      <ShowCase />
      <OurValues />
      <ContactUs />
    </div>
  );
}

export default App;
