/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Typography from 'components/Typography';
import { UseFormRegister } from 'react-hook-form';

const Textarea = ({
  name,
  label,
  placeholder,
  register,
}: {
  name: string;
  label: string;
  placeholder?: string;
  register: UseFormRegister<any>;
}) => {
  return (
    <div
      css={css`
        position: relative;
        height: 100%;
      `}
    >
      <Typography
        size={20}
        lineHeight={28}
        color="#c7c7c7"
        as="label"
        css={css`
          position: absolute;
          left: 20px;
          top: 16px;
        `}
      >
        {label}
      </Typography>
      <textarea
        placeholder={placeholder}
        css={css`
          width: 100%;
          height: 100%;
          background: #191919;
          padding: 56px 20px 20px;
          border: 0;
          display: block;
          font-size: 16px;
          line-height: 20px;
          color: #9e9e9e;
          resize: none;
        `}
        {...register(name, { required: true })}
      />
    </div>
  );
};

export default Textarea;
