/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { PopupButton } from '@typeform/embed-react';
import Wrapper from 'components/Wrapper';
import Typography from 'components/Typography';
import { useForm } from 'react-hook-form';
import Input from 'components/Forms/Input';
import Textarea from 'components/Forms/Textarea';
import Button from 'components/Button';

interface IFormValues {
  name: string;
  email: string;
  subject: string;
  message: string;
}

const ContactUs = () => {
  const { register, handleSubmit } = useForm<IFormValues>();

  const onSubmit = (values: IFormValues) => {};

  return (
    <section
      id="contact-us"
      css={css`
        padding-bottom: 100px;
      `}
    >
      <Wrapper>
        <Typography
          as="h2"
          size={20}
          lineHeight={30}
          gradient
          css={css`
            position: relative;
            text-align: center;
            padding: 28px 0;
            margin: 0;

            &:after {
              position: absolute;
              width: 200px;
              height: 1px;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              content: '';
              background: linear-gradient(to right, #00fff6, #415aff);
            }
          `}
        >
          Contact us now
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          css={css`
            position: relative;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 60px;
              align-items: stretch;
              padding: 48px 0;

              @media screen and (max-width: 768px) {
                display: block;
              }
            `}
          >
            <div
              css={css`
                width: 50%;

                @media screen and (max-width: 768px) {
                  width: 100%;
                }

                > div {
                  margin-bottom: 26px;

                  &:last-child {
                    margin-bottom: 0;

                    @media screen and (max-width: 768px) {
                      margin-bottom: 26px;
                    }
                  }
                }
              `}
            >
              <Input
                name="name"
                label="Full name:"
                register={register}
                padding={100}
              />
              <Input
                name="email"
                label="Email address:"
                register={register}
                padding={140}
              />
              <Input
                name="subject"
                label="Subject:"
                register={register}
                padding={100}
              />
            </div>
            <div
              css={css`
                width: 50%;

                @media screen and (max-width: 768px) {
                  width: 100%;
                }
              `}
            >
              <Textarea
                name="message"
                label="Your message:"
                register={register}
              />
            </div>
          </div>
          <Button type="submit">Send message now</Button>

          <PopupButton
            css={css`
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border: 0;
              background: transparent;
              z-index: 10;
              cursor: pointer;
            `}
            id="Fvu1zM8P"
          />
        </form>
      </Wrapper>
    </section>
  );
};

export default ContactUs;
