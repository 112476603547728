/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Typography from 'components/Typography';
import { UseFormRegister } from 'react-hook-form';

const Input = ({
  name,
  label,
  padding,
  register,
}: {
  name: string;
  label: string;
  padding?: number;
  register: UseFormRegister<any>;
}) => {
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <Typography
        size={20}
        lineHeight={28}
        color="#c7c7c7"
        as="label"
        css={css`
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
        `}
      >
        {label}
      </Typography>
      <input
        css={css`
          width: 100%;
          background: #191919;
          padding: 16px 20px 16px ${padding ? `${padding}px` : '180px'};
          border: 0;
          display: block;
          font-size: 20px;
          line-height: 28px;
          color: white;
        `}
        {...register(name, { required: true })}
      />
    </div>
  );
};

export default Input;
