/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { PropsWithChildren } from 'react';

const Wrapper = ({
  maxWidth = 1440,
  children,
  className,
}: PropsWithChildren<{ maxWidth?: number; className?: string }>) => (
  <div
    css={css`
      max-width: ${maxWidth}px;
      margin: 0 auto;
      padding: 0 40px;

      @media screen and (max-width: 768px) {
        padding: 0 20px;
      }
    `}
    className={className}
  >
    {children}
  </div>
);

export default Wrapper;
