/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import InfoCard from 'components/InfoCard';
import Wrapper from 'components/Wrapper';
import DititalAssetAdvisory from 'assets/images/digital-asset-advisory.png';
import DititalAssetRetail from 'assets/images/digital-asset-retail.png';
import MarketingSupport from 'assets/images/marketing-support.png';
import { ReactComponent as Crypto } from 'assets/svgs/crypto-tokens.svg';
import { ReactComponent as CopyRights } from 'assets/svgs/copyrights.svg';
import { ReactComponent as Virtual } from 'assets/svgs/virtual-and-pysical.svg';
import { ReactComponent as NFT } from 'assets/svgs/nft.svg';

const Service = () => {
  return (
    <section
      id="services"
      css={css`
        padding: 100px 0;

        @media screen and (max-width: 1000px) {
          padding: 50px 0;
        }
      `}
    >
      <Wrapper>
        <ul
          css={css`
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            gap: 60px;

            @media screen and (max-width: 768px) {
              display: block;
            }

            > li {
              flex: 1 1 0px;

              @media screen and (max-width: 768px) {
                margin-bottom: 32px;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          `}
        >
          <li>
            <InfoCard
              title="Digital Asset Advisory"
              image={DititalAssetAdvisory}
            >
              <p>
                Tailored strategic advisory serivces to help client companies
                navigate crypto asset building, tokenomics design, and community
                operations.
              </p>

              <p>
                From ideation to execution, we work closely with our clients to
                scale effectively and remains future-ready in the evolving
                digital landscape and market situations.
              </p>
            </InfoCard>
          </li>
          <li>
            <InfoCard
              title="Digital Asset Retail Solutions"
              image={DititalAssetRetail}
            >
              <p>
                Allowing clients to access massive liquidity globally and
                empowering client companies' digital asset sales campaigns with
                our proven global crypto KOL network and extensive community
                coverage.
              </p>

              <p>
                Customized technical systems to streamline transactions and
                provide transparent sales tracking, ensuring client assets are
                sold seamlessly and at competitive rates.
              </p>

              <p>Assets supported (including but not limited to)</p>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: start;
                  gap: 8px;

                  svg {
                    max-width: 60px;

                    @media screen and (max-width: 1000px) {
                      max-width: 40px;
                    }
                  }

                  div {
                    flex: 1 1 0;
                    width: 0;
                    text-align: center;
                  }

                  span {
                    padding-top: 20px;
                    display: block;
                    font-size: 12px;
                    line-height: 18px;
                    word-wrap
                  }
                `}
              >
                <div>
                  <Virtual />
                  <span>Virtual and Physical Notes</span>
                </div>
                <div>
                  <CopyRights />
                  <span>Digitized Content Copyright</span>
                </div>
                <div>
                  <NFT />
                  <span>NFTs</span>
                </div>
                <div>
                  <Crypto />
                  <span>Crypto Tokens</span>
                </div>
              </div>
            </InfoCard>
          </li>
          <li>
            <InfoCard title="Marketing Support" image={MarketingSupport}>
              <p>
                Elevate your brand’s presence and drive impactful community
                engagement within the Web3 ecosystem.
              </p>

              <p>
                By leveraging data-driven insights, we develop strategic
                initiatives that boost visibility and cultivate genuine
                relationships between you and your target audience.
              </p>
            </InfoCard>
          </li>
        </ul>
      </Wrapper>
    </section>
  );
};

export default Service;
