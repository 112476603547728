/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ElementType, PropsWithChildren } from 'react';

const TypographyBase = styled.span`
  -webkit-font-smoothing: auto;
`;

const Typography = ({
  as = 'span',
  size = 20,
  mediumSize = 16,
  smallSize = 14,
  lineHeight = 28,
  mediumLineHeight = 24,
  smallLineHeight = 18,
  color = 'white',
  weight = 'normal',
  children,
  gradient,
  className,
}: PropsWithChildren<{
  as?: ElementType;
  size?: number;
  mediumSize?: number;
  smallSize?: number;
  lineHeight?: number;
  mediumLineHeight?: number;
  smallLineHeight?: number;
  color?: string;
  weight?: string;
  gradient?: boolean;
  className?: string;
}>) => {
  return (
    <TypographyBase
      as={as}
      css={css`
        font-size: ${size}px;
        font-weight: ${weight};
        line-height: ${lineHeight}px;
        color: ${color};
        ${gradient &&
        `background: linear-gradient(to right, #00fff6, #415aff);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;`}

        @media screen and (max-width: 1000px) {
          font-size: ${mediumSize}px;
          line-height: ${mediumLineHeight}px;
        }

        @media screen and (max-width: 768px) {
          font-size: ${smallSize}px;
          line-height: ${smallLineHeight}px;
        }
      `}
      className={className}
    >
      {children}
    </TypographyBase>
  );
};

export default Typography;
